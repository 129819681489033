import he from 'he'

const createProductSchema = (brand, siteUrl, product) => {

  if (!product) return null

  let variants = product.acf.colours?.map((el, i) => {
    return `{
      "@type" : "Offer",
      "availability" : "http://schema.org/InStock",
      "price" : "${ product.acf.basePrice.substring(1) }",
      "priceCurrency" : "AUD",
      "url" : "${ siteUrl }/products/${ product.slug }",
      "itemOffered" :
      {
          "@type" : "Product",
          "image": "${ siteUrl }${ el.gallery[0].localFile.publicURL }"
          "name" : "${ el.colour.name }",
          "url" : "${ siteUrl }/products/${ product.slug }"
      }
    }`
  }).join()

  return `
  {
    "@context": "http://schema.org/",
    "@type": "Product",
    "name": "${ he.encode(product.title) }",
    "url": "${ siteUrl }/products/${ product.slug }",
    "image": [
      "${ siteUrl }${ product.acf.colours[0].gallery[0].localFile.publicURL }"
    ],
    "description": "${ he.encode(product.acf.whyChoose.content.replace(/<[^>]+>/g, '')) }",
    "brand": {
      "@type": "Thing",
      "name": "${brand}"
    },
    "offers": [
      ${ variants }
    ]
  }
  `
}

export { createProductSchema }
